<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b">
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar Kategori <b>Bangsal</b>
      </div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        v-b-modal.modal-add
        @click="handleAdd()">Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>

            <!-- Filter -->
            <div class="row align-items-center justify-content-end mt-n3">
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Berdasar Nama"
                    @keyup="filterByName"></b-form-input>
                </b-input-group>
              </div>
            </div>

            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              responsive>
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }" />
              </template>
              <template #cell(actions)="data">
                <!-- <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="$router.push({ path: '/beds/detail/'+data.item.id })"
                > <i class="fas fa-eye px-0"></i> </b-button> -->
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-modal.modal-add
                  v-b-tooltip.hover
                  title="Ubah"
                  v-if="manipulateBtn == true"
                  @click="handleEdit(data.item.id)"> <i class="fas fa-edit px-0"></i> </b-button>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  v-if="manipulateBtn == true"
                  @click="handleDelete(data.item.id)"> <i class="fas fa-trash px-0"></i> </b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"></b-pagination>
          </template>
        </Card>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
      id="modal-add"
      title="Kategori Bangsal"
      @ok="modalOnSubmit"
      @hidden="modalOnHide">
      <b-form @submit.stop.prevent="formOnSubmit">
        <!-- Input Name -->
        <b-form-group
          id="input-group-name"
          label="Nama Kategori:"
          label-for="input-name">
          <b-form-input
            id="input-name"
            v-model="form.name"
            required
            placeholder="Nama Kategori">
          </b-form-input>
          <small class="text-danger">{{ error.name }}</small>
        </b-form-group>

        <!-- Input Description -->
        <b-form-group
          id="input-group-description"
          label="Deskripsi:"
          label-for="input-description">
          <b-form-textarea
            id="input-description"
            v-model="form.description"
            rows="3"
            required
            placeholder="Deskripsi"></b-form-textarea>
        </b-form-group>
      </b-form>
    </b-modal>

  </div>
</template>

<script>

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import Card from '@/view/content/Card.vue'
import { debouncer } from "@/core/modules/Helper.js"
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'

export default {

  components: {
    Card
  },

  data() {
    return {
      route: "bed-categories",
      // Filter
      filter: {
        name: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Form
      form: {
        name: "",
        description: "",
      },
      // Error
      error: {
        name: "",
        description: "",
      },
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Kategori",
          sortable: true,
        },
        {
          key: "description",
          label: "Deskripsi",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      manipulateBtn: false,
    }
  },

  methods: {

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`
      let response = await module.paginate('bed-categories', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total

      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }
      this.items = response.data
    },

    filterByName() {
      debouncer(() => { this.pagination() }, 500)
    },

    handleAdd() {
      this.route = 'bed-categories'
    },

    async handleEdit(id) {
      this.form = await module.get('bed-categories/' + id)
      this.form['_method'] = 'put'
      this.route = 'bed-categories/' + id
    },

    async handleDelete(id) {
      let result = await module.delete('bed-categories/' + id)
      if (result) {
        this.pagination()
      }
    },

    modalOnSubmit(evt) {
      evt.preventDefault()
      this.formOnSubmit()
    },

    modalOnHide() {
      this.error = validation.clearValidationError(this.error)
      this.form = validation.clearForm(this.form)
      delete this.form['_method']
    },

    async formOnSubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        // Hide Modal
        this.$bvModal.hide('modal-add')
        // Refresh Table
        this.pagination()
      }
    },

    // access management
    async setActiveMenu() {

      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        

        if (access_right[a] == "1305") {
          this.manipulateBtn = true
        }

      }
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bangsal" },
      { title: "Kategori Bangsal" },
    ])
    this.pagination()
    this.setActiveMenu()
  },

}
</script>

<style></style>